import React, { useEffect, useState } from "react";
import axios from "axios";
import Topbar from "./Components/TopBar";
import { Box, CircularProgress, Typography } from "@mui/material";
import PitchContainer from "./Components/PitchContainer";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// 2. Create a TypeScript interface for your data
interface PlayerData {
  id: string;
  age: number;
  name: string;
  bestRating: string;
  bestPotRating: string;
  nat: string;
  club: string;
  ufid: number;
  value: number;
  wage: number;
  position: string;
}

// 3. Create a React component to fetch and display the data
const App: React.FC = () => {
  const [data, setData] = useState<PlayerData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    // Function to fetch data from the backend
    const fetchData = async () => {
      try {
        setIsLoading(true); // Set loading state before the request
        const response = await axios.get<PlayerData[]>(
          "https://squad-manager-pro-backend.onrender.com/players"
        );
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false); // Ensure loading state is cleared
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, []); // The empty dependency array ensures that the effect runs only once after the initial render

  return (
    <DndProvider backend={HTML5Backend}>     
    
    <div>
      <div>
        <Topbar  />
      </div>

      {isLoading ? (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
        {/* Your loading animation here */}
        <CircularProgress size={50} /> {/* Example using Material UI */}
        <Typography sx={{ml:3}}>Data is fetching from backend server. This may take a few minutes.</Typography>
      </Box>
    ) : (
      <PitchContainer data={data} />
    )}
      
    </div>
    </DndProvider>
  );
};

export default App;
