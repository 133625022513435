import { Box } from "@mui/material";
import { useDrag as useDragHook, useDrop as useDropHook } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

interface PlayerData {
    id: string;
    age: number;
    name: string;
    bestRating: string;
    bestPotRating: string;
    nat: string;
    club: string;
    ufid: number;
    value: number;
    wage: number;
    position: string;
  }

interface PlayerPositionProps {
    x: number; // Horizontal position on the pitch
    y: number; // Vertical position on the pitch
    player?: PlayerData;
  }

  // ... PlayerPositionProps interface

const PlayerBox: React.FC<PlayerPositionProps> = ({ x, y, player }) => {
    
    const [{ isDragging }, dragRef] = useDragHook({
        type: "PLAYER",
        item: { id: player?.id, name: player?.name },
        collect: (monitor) => ({
          isDragging: monitor.isDragging(),
        }),
      });
    
      const [{ isOver }, dropRef] = useDropHook({
        accept: "PLAYER",
        drop: (item: { id: string, name: string }) => {
          // Handle player drop logic here
        },
        collect: (monitor) => ({
          isOver: monitor.isOver(),
        }),
      });


    return (
      <Box
        style={{
          position: "absolute",
          left: `${x}%`,
          bottom: `${y}px`,
          transform: "translateX(-50%)",
          width: "60px", 
          height: "60px",
          backgroundColor: "transparent",
          backgroundImage: `url(${process.env.PUBLIC_URL}./Images/galatasarayShirt.png)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >

      </Box>
    );
  };

  export default PlayerBox;