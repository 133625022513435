import React, { RefObject, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Card, CardActions, Dialog, DialogActions, DialogContent, DialogTitle, Paper } from "@mui/material";
import PlayerBox from "./PlayerBox";
import { useDrag } from "react-dnd";

interface PlayerData {
  id: string;
  age: number;
  name: string;
  bestRating: string;
  bestPotRating: string;
  nat: string;
  club: string;
  ufid: number;
  value: number;
  wage: number;
  position: string;
}

const PitchContainer: React.FC<{ data: PlayerData[] }> = ({ data }) => {

    const [openPopup, setOpenPopup] = useState(false);
  
    const handlePlayerClick = (player: PlayerData) => {
      if (player.name === "Deniz Seda Gündoğdu") {
        setOpenPopup(true);
      }
    };
   

  return (
    <Box
      sx={{
        boxShadow: '1px 2px 9px #000000',
        height: "550px",
        width: "90%",
        marginTop: 5,
        marginLeft: "5%",
        marginRight: "10%",
        marginBottom: 5,
        display: "flex",
      }}
    >
      <Grid container>
        <Grid
          item
          xs={6}
          sx={{
            position: "relative",
            backgroundImage: `url(${process.env.PUBLIC_URL}./Images/footballPitch.png)`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
            {/*GK*/}
            <PlayerBox x={50} y={30}/>
            {/*LB*/}
            <PlayerBox x={15} y={125}/>
            {/*CB*/}
            <PlayerBox x={40} y={125}/>
            {/*CB*/}
            <PlayerBox x={60} y={125}/>
            {/*RB*/}
            <PlayerBox x={85} y={125}/>
            {/*MCL*/}
            <PlayerBox x={40} y={260}/>
            {/*MCR*/}
            <PlayerBox x={60} y={260}/>
            {/*AMC*/}
            <PlayerBox x={50} y={360}/>
            {/*AML*/}
            <PlayerBox x={15} y={360}/>
            {/*AMR*/}
            <PlayerBox x={85} y={360}/>
            {/*ST*/}
            <PlayerBox x={50} y={460}/>

        </Grid>

        <Grid item xs={6} sx={{ bgcolor: "white",position: "relative"}}>

            <Dialog open={openPopup} onClose={() => setOpenPopup(false)}>
            <DialogTitle>Special message from the developer</DialogTitle>
            <DialogContent>
                 <h1>Best player in the &#127758; is Deniz Seda Gündoğdu &#128154; I love you. </h1>
            </DialogContent>
            <DialogActions>
            <button onClick={() => setOpenPopup(false)}>Close</button>
            </DialogActions>
            </Dialog>

            <Paper sx={{ maxHeight: 550, overflowY: "auto", position: "relative"}}>
            {/* Enable scrolling */}
            {data.map((player) => (
                <div key={player.id}>
                <Card key={player.id} onClick={() => handlePlayerClick(player)}
                    sx={{
                    ":hover": {
                        backgroundColor: "lightgreen",
                        padding: 2,
                    },
                    padding: 1,
                    }}
                >                  
                    {player.name}
                    {player.name === "Deniz Seda Gündoğdu" && <span>❤️</span>}
                </Card>
                </div>
            ))}
            </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PitchContainer;
